const Footer = () => {
    return (
        <div id="footer">
            <div>&copy; 2024 Succumb to Darkness</div>
            <span>inquiries: succumb.to.darkness@gmail.com</span>
        </div>
    );
}

export default Footer;
